import { Fragment, useContext, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Navbar } from './components/Navigation/Navbar';
import { Editor, Home, Snippet, Snippets, Auth, Profile } from './containers';
import { decodeToken, ProtectedRoute } from './utils';
import { AuthContext, SnippetsContext } from './store';

export const App = () => {
  const { autoLogin, logout } = useContext(AuthContext);
  const { getSnippets, countTags } = useContext(SnippetsContext);

  useEffect(() => {
    autoLogin();

    getSnippets();
    countTags();

    const checkTokenValidity = setInterval(() => {
      if (localStorage.token) {
        const { exp: expiresAt } = decodeToken(localStorage.token);

        if (Date.now() > expiresAt * 1000) {
          logout();
        }
      }
    }, 1000);

    return () => window.clearInterval(checkTokenValidity);
  }, []);

  return (
    <Fragment>
      <Navbar />
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/snippets' component={Snippets} />
        <Route path='/snippet/:id' component={Snippet} />
        <Route path='/snippet/:id' component={Snippet} />
        <Route path='/auth' component={Auth} />
        <ProtectedRoute path='/editor/:id?' component={Editor} />
        <ProtectedRoute path='/me' component={Profile} />
      </Switch>
    </Fragment>
  );
};
